<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="客户姓名">
              <el-input v-model="form.name" :clearable="true" placeholder="请输入客户姓名"></el-input>
            </el-form-item>
            <el-form-item label="客户电话">
              <el-input v-model="form.tel" :clearable="true" placeholder="请输入客户电话"></el-input>
            </el-form-item>
            <el-form-item label="客户类别">
              <el-select clearable v-model="form.customerType" placeholder="请选择客户类型">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in rightsType" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品类别">
              <el-select clearable v-model="form.registe" placeholder="请选择客户类型">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in productTypeList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch" @click="getAllCustomers(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button type="primary" plain icon="el-icon-plus" @click="addNewCustomer">新增客户</el-button>
      </div>
      <div class="table-block">
        <el-table :data="tableData" v-loading="loading" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565'}" :cell-style="{'text-align':'center'}" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column prop="customerName" label="客户姓名"> </el-table-column>
          <el-table-column prop="productName" label="会员产品"> </el-table-column>
          <el-table-column prop="tel" label="手机号"> </el-table-column>
          <el-table-column prop="customerProductVos[0].memberName" label="会员类别"> </el-table-column>
          <el-table-column prop="integral" label="积分"> </el-table-column>
          <el-table-column prop="customerProductVos[0].endTime" label="会员到期时间"> </el-table-column>

          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button @click="addNew(scope.row, 2)" type="text" size="small">详情</el-button>
              <el-button size="small" type="text" @click="handleUpdate(scope.row)">修改</el-button>

              <!--rightsOwnerSignType==null线上签字，可以预览-->
              <!-- <el-button v-if="scope.row.productType==='HJ' && scope.row.rightsOwnerSignType==null"
                         @click="updateOfflineRightsOwnerSign(scope.row,'会籍订单')" type="text" size="small">转为线下签字</el-button> -->
              <el-button v-if="scope.row.privacyProtectionAgreementFlag && !scope.row.rightsownerSignType" @click="updateOfflineRightsOwnerSign(scope.row,'隐私服务协议',true)" type="text" size="small">隐私服务协议线下签字
              </el-button>
              <el-button v-if="scope.row.serviceAgreementFlag && !scope.row.rightsOwnerSignType" @click="updateOfflineRightsOwnerSign(scope.row,'服务协议',false)" type="text" size="small">服务协议线下签字</el-button>

              <!--rightsOwnerSignType==1线上签字，可以预览-->
              <el-button v-if="scope.row.productType!='DP' && scope.row.rightsownerSignType==='1'" @click="previewFile(scope.row,true,'隐私服务协议')" type="text" size="small">隐私服务协议查看签字</el-button>
              <el-button v-if="scope.row.productType!='DP' && scope.row.rightsOwnerSignType==='1'" @click="previewFile(scope.row,false,'服务协议')" type="text" size="small">服务协议查看签字</el-button>

              <!--rightsOwnerSignType==2，不可操作-->
              <el-button v-if="scope.row.productType!='DP' && scope.row.rightsownerSignType==='2'" disabled type="text" size="small" title="签字已转为线下">隐私服务协议已转线下签字</el-button>
              <el-button v-if="scope.row.productType!='DP' && scope.row.rightsOwnerSignType==='2'" disabled type="text" size="small" title="签字已转为线下">服务协议已转线下签字</el-button>

            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <refund v-if="showRefund" @closeDialog="closeDialog"></refund>
    <addUsers v-if="showAddUser" @closeAdd="closeAdd" :info="info" :title="title"></addUsers>
    <record :recordInfo="recordInfo" v-if="showRecord" @closerecord="closerecord"></record>
    <el-dialog :visible.sync="dialogVisiblesee" width="40%" class="Visiblesee" fullscreen style="text-align: center; background-color: red">
      <iframe :src="previewUrl" alt="" frameborder="0" style="width: 70%; height: 860px" scrolling="no" />
    </el-dialog>
    <!--预览签名协议图片，下载签名协议pdf-->
    <el-image-viewer v-if="showSignInfo" :on-close="()=>{showSignInfo=false}" :url="rightsOwnerSignInfo.rightsOwnerSignAgreement" :loading="true" :url-list="rightsOwnerSignInfo.signAgreementImageList" />

    <!--<el-dialog title="预览签名协议"-->
    <!--:visible.sync="showSignInfo"-->
    <!--width="50%" height="auto">-->
    <!--<el-carousel indicator-position="outside" :autoplay="false" >-->
    <!--<el-carousel-item v-for="item in rightsOwnerSignInfo.signAgreementImageList" :key="item">-->
    <!--<el-image :src="item">-->
    <!--<div slot="placeholder" class="image-slot">-->
    <!--加载中<span class="dot">...</span>-->
    <!--</div>-->
    <!--</el-image>-->
    <!--</el-carousel-item>-->
    <!--</el-carousel>-->
    <!--<el-button size="small">下载协议PDF文件</el-button>-->
    <!--</el-dialog>-->
  </div>
</template>

<script>
import refund from '@/views/customerManagement/component/refund'
import addUsers from '@/views/customerManagement/component/addUsers'
import record from '@/views/customerManagement/component/record'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { postRequest } from '@/api'

export default {
  components: { refund, addUsers, record, ElImageViewer },
  data() {
    return {
      form: {
        name: '',
        registe: '',
        customerType: '',
      },
      options: [
        {
          label: '个人用户',
          value: 1.1,
        },
        {
          label: '家庭用户',
          value: 2.2,
        },
        {
          label: '精英用户',
          value: 2.3,
        },
      ],
      tableData: [],
      dialogVisiblesee: false,
      showRefund: false,
      showAddUser: false,
      showRecord: false,
      righ: {},
      showSignInfo: false,
      rightsOwnerSignInfo: {},
      previewUrl: '',
      title: '',
      multipleSelection: [],
      info: {},
      rightsType: [],
      productTypeList: [],
      recordInfo: {},
      total: null,
      pageNo: 1,
      pageSize: 10,
      loading: true,
      disabledSearch: false,
    }
  },
  mounted() {
    // 查询客户类别列表
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '06' }).then(res => {
      console.log(res, '家庭、精英、SVIP')
      this.rightsType = res
    })
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '04' }).then(res => {
      console.log(res, '单品、会籍')
      this.productTypeList = res
    })
    this.getAllCustomers()
  },
  methods: {
    previewFile(file, type,name) {
      this.$confirm(`是否预览 ${name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
      })
        .then(() => {
          if (type) {
            this.previewUrl = file.serviceAgreementUrl
          } else {
            this.previewUrl = file.privacyServiceAgreementUrl
          }
          this.dialogVisiblesee = true
        })
        .catch(() => {})
    },
    refundMoney() {
      this.showRefund = true
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getAllCustomers()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.pageNo = val
      this.getAllCustomers()
    },
    closeDialog() {
      this.showRefund = false
    },
    closeAdd() {
      this.showAddUser = false
      this.getAllCustomers()
    },
    closerecord() {
      this.showRecord = false
      this.getAllCustomers()
    },
    handleUpdate(val) {
      console.log(val)
      this.info = val
      this.showAddUser = true
    },
    // searchRes(){
    //   let data = {
    //     customerName: this.form.name ? this.form.name : '',
    //     customType: this.form.customerType ? this.form.customerType : '',
    //     identificationType: this.form.registe ? this.form.registe : ''
    //   }
    //   this.getAllCustomers(data)
    // },
    addNew(row) {
      console.log(row, 'row')
      this.recordInfo = row
      this.showRecord = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    //修改为线下
    updateOfflineRightsOwnerSign(val, name, type) {
      this.$confirm(`将${name}签字转为线下, 修改后客户将不在APP中签字, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let data
          if (type) {
            data = {
              id: val.id,
              orderId: val.orderId,
              privacyProtectionAgreementFlag: true,
              serviceAgreementFlag: false,
            }
          } else {
            data = {
              id: val.id,
              orderId: val.orderId,
              privacyProtectionAgreementFlag: false,
              serviceAgreementFlag: true,
            }
          }
          postRequest('/customer/updateOfflineRightsOwnerSign/', data).then(res => {
            console.log(res)
            this.$message({
              message: '修改',
              type: 'success',
            })
            this.getAllCustomers()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消修改',
          })
        })
      console.log(val, 'line')
    },

    //下载pdf
    downloadAgreementPdf() {
      let url = this.rightsOwnerSignInfo.rightsOwnerSignAgreement
      let fileName = '会员协议.pdf'
      console.log('下载文件:', url)
      const el = document.createElement('a')
      el.style.display = 'none'
      el.target = '_blank'
      el.download = fileName
      el.href = url
      document.body.appendChild(el)
      el.click()
      document.body.removeChild(el)
    },

    //获取签名信息
    getRightsOwnerSignInfo(val, type) {
      this.rightsOwnerSignInfo = {}
      this.showSignInfo = true

      //绑定一个下载图标，下载pdf文件
      this.$nextTick(() => {
        let actions = document.querySelector('.el-image-viewer__actions__inner')
        let btn = document.createElement('i')
        btn.className = 'el-icon-download download-pdf'
        btn.title = '下载协议PDF文件'
        actions.appendChild(btn)
        document.querySelector('.download-pdf').onclick = this.downloadAgreementPdf
      })
      let data = {
        id: val.id,
        orderId: val.orderId,
      }
      postRequest('/customer/getRightsOwnerSignInfo/', data).then(res => {
        console.log('签字协议文件:', res.rightsOwnerSignAgreement)
        console.log('转换图片数量:', res.signAgreementImageList)
        this.rightsOwnerSignInfo = res
      })
    },
    handleDelete(val) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let data = {
            id: val.id,
          }
          postRequest('/customer/delete/', data).then(res => {
            console.log(res)
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.getAllCustomers()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除',
          })
        })
      console.log(val, 'line')
    },
    // 查询所有客户信息
    getAllCustomers(no) {
      this.loading = true
      this.disabledSearch = true
      let data = {
        customerName: this.form.name ? this.form.name : '',
        customType: this.form.customerType ? this.form.customerType : '',
        productType: this.form.registe ? this.form.registe : '',
        tel: this.form.tel ? this.form.tel : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize,
      }
      postRequest('/customer/queryCustomerPage/', data)
        .then(res => {
          console.log(res, '查询所有客户信息')
          this.loading = false
          this.disabledSearch = false
          this.tableData = res.data
          this.total = res.count
          res.data.map(item => {
            if (item.customerProductVos[0].memberCode == 'JY') {
              item.customerProductVos[0].memberName = '精英'
            } else if (item.customerProductVos[0].memberCode == 'JT') {
              item.customerProductVos[0].memberName = '家庭'
            } else if (item.customerProductVos[0].memberCode == 'GR') {
              item.customerProductVos[0].memberName = '个人'
            } else if (item.customerProductVos[0].memberCode == 'SVIP') {
              item.customerProductVos[0].memberName = 'SVIP'
            } else if (item.customerProductVos[0].memberCode == 'PT') {
              item.customerProductVos[0].memberName = '普通客户'
            }
          })
        })
        .catch(() => {
          this.loading = false
          this.disabledSearch = false
        })
    },
    addNewCustomer() {
      this.showAddUser = true
      this.info = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
</style>
