<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%" @close="handleClose">
      <div class="main">
        <el-card class="left">
          <div>
            <div class="header">
              <div>
                <img class="pic"
                  :src="infomation.customPicUrl ? infomation.customPicUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'"
                  alt="">
              </div>
              <div>
                <h4>{{ infomation.customerName }}</h4>
              </div>
              <div>
                会员号: {{ infomation.vipCode }}
              </div>
            </div>
            <div class="body">
              <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="产品类型:">
                  {{ infomation.productName || '' }}
                </el-form-item>
                <el-form-item label="团体类别:">{{ result.memberName || '' }}</el-form-item>
                <el-form-item label="团体名称:">{{ result.teamName || '' }}</el-form-item>
                <el-form-item label="电话:">{{ infomation.tel }}</el-form-item>
                <el-form-item label="医生:">{{ result.houseDocterName }}</el-form-item>
                <el-form-item label="管家:">{{ result.houseKeeperName }}</el-form-item>
                <el-form-item label="家庭住址:">{{ infomation.addr }}</el-form-item>
                <el-form-item label="服务时间:">{{ result.startTime ? result.startTime.substring(0, 10) : '' }}至{{
                  result.endTime ? result.endTime.substring(0, 10) : '' }}</el-form-item>

                <div v-if="showOther">
                  <el-form-item label="会员延期服务:">
                    <el-button type="text" @click="open">延期</el-button>
                  </el-form-item>
                  <div v-if="isShow">
                    <el-row>
                      <el-col>
                        <el-date-picker v-model="value1" type="date" placeholder="选择日期" disabled style="width:45%">
                        </el-date-picker> 至
                        <el-date-picker v-model="value2" value-format="yyyy-MM-dd" type="date" @input="changeDate"
                          :picker-options="pickerOptions" placeholder="选择结束日期" style="width:45%"> </el-date-picker>
                      </el-col>
                    </el-row>
                    <div style="margin-top:20px;">
                      <p>备注:</p>
                      <el-input type="textarea" @input="changeInput" v-model="form.desc"></el-input>
                    </div>
                    <el-row class="button">
                      <el-button @click="cancelDelay">取消</el-button>
                      <el-button @click="delay" type="primary">确定</el-button>
                    </el-row>
                  </div>
                </div>
                <el-form-item v-else label="备注:">
                  <el-input type="textarea" @input="changeInput" v-model="form.desc"></el-input>
                </el-form-item>

                <el-form-item v-if="result.productId == '90'" label="升级服务:">
                  <el-button @click="upgrade(false)" size="mini" type="text">肠道健康管理</el-button>
                </el-form-item>
                <el-form-item v-if="infomation.productType == 'TJ'" label="升级服务:">
                  <el-button @click="getNameByType()" size="mini" type="text">会员计划</el-button>
                </el-form-item>
                <el-dialog title="升级服务" :visible.sync="upgradeDialogVisible" width="15%" :close-on-click-modal='false'>
                  <el-form-item label="升级产品名称:" prop="productId">
                    <el-select v-model="form.productId" placeholder="请选择升级产品名称">
                      <el-option v-for="(item, index) in productList" :key="index" :label="item.productName"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="upgradeDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="upgrade(true)">确 定</el-button>
                  </span>
                </el-dialog>
              </el-form>
            </div>
          </div>
        </el-card>
        <div class="right">
          <el-card class="buttonLine">
            <el-menu :default-active="activeNum" class="el-menu-vertical-demo" @select="handleClick">
              <el-menu-item v-for="(item, index) in newButtonsList" :key="index" :index="item.code">
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </el-menu>
          </el-card>
          <el-card class="tableInfo">
            <div v-show="chooseIndex == 0">
              <div>
                <el-button type="primary" plain class="task" @click="handleAdd" icon="el-icon-plus">任务</el-button>
              </div>
              <el-table :data="tableData1" border v-loading="loading1" max-height="605"
                :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565' }"
                :cell-style="{ 'text-align': 'center' }" style="width: 100%">
                <el-table-column prop="tasktypeCode" label="类型">
                  <template slot-scope="scope">
                    <el-select @change="changeTaskName(scope.row.tasktypeCode, scope.$index)"
                      v-model="scope.row[scope.column.property]">
                      <el-option v-for="(row, index) in typeList" :key="index" :label="row.dictionaryName"
                        :value="row.dictionaryCode">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="samplingTime" label="时间">
                  <template slot-scope="scope">
                    <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" @change="changeTime"
                      :picker-options="startDateAndEndDate" v-model="scope.row[scope.column.property]"
                      style="width: 100%;">
                    </el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row[scope.column.property]"></el-input>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="remark" label="体检方案" align="center">
                  <template slot-scope="scope">
                    <div class="operateBox">
                      <el-upload v-if="!scope.row.medicalExaminationProgram && scope.row.tasktypeCode!='071'&& scope.row.tasktypeCode!='072'&& !scope.row.substatus" :show-file-list="false" :action="fileUrl" :before-upload="fileBeforeAvatarUpload"
                        :on-success="(file) =>  { return fileSuccess(file, 'medicalExaminationProgram',scope.$index)}">
                        <div class="uploadBtnBox">
                          <el-button type="text">上传</el-button>
                        </div>
                      </el-upload>
                      <el-button type="text" v-if="scope.row.medicalExaminationProgram" style="margin-left:10px" @click="preview(scope.row.medicalExaminationProgram)">预览</el-button>
                      <el-button type="text" v-if="scope.row.medicalExaminationProgram && !scope.row.substatus" @click="delFile('medicalExaminationProgram',scope.$index)">删除</el-button>
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column prop="remark" label="注意事项">
                  <template slot-scope="scope">
                    <div class="operateBox">
                      <el-upload
                        v-if="!scope.row.precautions && scope.row.tasktypeCode != '071' && scope.row.tasktypeCode != '072' && scope.row.tasktypeCode != '076' && !scope.row.substatus"
                        :show-file-list="false" :action="fileUrl" :before-upload="fileBeforeAvatarUpload"
                        :on-success="(file) => { return fileSuccess(file, 'precautions', scope.$index) }">
                        <div class="uploadBtnBox">
                          <el-button type="text">上传</el-button>
                        </div>
                      </el-upload>
                      <el-button type="text" v-if="scope.row.precautions" style="margin-left:10px"
                        @click="preview(scope.row.precautions)">预览</el-button>
                      <el-button type="text" v-if="scope.row.precautions && !scope.row.substatus"
                        @click="delFile('precautions', scope.$index)">删除</el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                  <template slot-scope="scope">
                    <div v-if="scope.row.substatus != '' && scope.row.substatus != '101'">
                      <el-button type="primary" @click="showDetail(scope.row)" plain size="mini">查看</el-button>
                      <el-button type="danger" @click="handleDeleteprogress(scope.$index, scope.row, false)" plain
                        size="mini">删除</el-button>
                    </div>
                    <div v-else>
                      <el-button @click="handleOpen(scope.row)" plain v-show="scope.row.substatus == '101'" type="primary"
                        size="mini">开启</el-button>
                      <el-button type="primary" v-show="scope.row.substatus == ''" @click="handleSure(scope.row)" plain
                        size="mini">确定</el-button>
                      <el-button type="danger" @click="handleDeleteprogress(scope.$index, scope.row, true)" plain
                        size="mini">删除</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </div>
            <div class="tableInfo" v-show="chooseIndex == 1">
              <!-- <el-steps v-if="tableData2.length != 0" align-center>
              <el-step v-for="(item, index) in tableData2" :key="index" :title="item.operateMatters" :description="item.operateTime"></el-step>
            </el-steps> -->
              <div v-if="tableData2.length > 0">
                <div class="bigOutBox">
                  <div class="outBox" v-for="(item, index) in tableData2" :key="index">
                    <div class="line"></div>
                    <div class="word">
                      <div class="outPoint" :style="outPoint(index)">
                        <div class="point" :style="point(index)"></div>
                      </div>&nbsp;&nbsp;
                      <span class="time">{{ item.operateTime }}</span>&nbsp;&nbsp;
                      <span>{{ item.operateMatters }}</span>
                    </div>
                    <div class="line"></div>
                  </div>
                </div>
                <p class="lastTen">只展示最近二十次操作记录</p>
              </div>
              <p v-else style="text-align: center; color: #999;">暂无数据</p>
            </div>
            <div class="tableInfo" v-show="chooseIndex == 2">
              <div class="button-1">
                <el-button plain icon="el-icon-download" @click="downloadFile" type="primary">导出</el-button>
              </div>
              <el-table :data="tableData3" v-loading="loading2"
                :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565' }"
                :cell-style="{ 'text-align': 'center' }" style="width: 100%">
                <el-table-column prop="companyName" label="机构">
                </el-table-column>
                <el-table-column prop="orderTime" width="150" label="订单时间">
                </el-table-column>
                <el-table-column prop="productName" label="产品类型">
                </el-table-column>
                <el-table-column prop="totalPrice" label="收费金额">
                </el-table-column>
                <el-table-column prop="status" label="状态">
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
                :total="total1">
              </el-pagination>
            </div>
            <div class="tableInfo" v-show="chooseIndex == 3">
              <el-table :data="tableData4" v-loading="loading3"
                :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565' }"
                :cell-style="{ 'text-align': 'center' }" style="width: 100%">
                <el-table-column prop="companyName" label="机构">
                </el-table-column>
                <el-table-column prop="tasktypeName" width="80" label="预约项目">
                </el-table-column>
                <el-table-column prop="taskStartTime1" label="预约时间">
                </el-table-column>
                <el-table-column prop="updateTime" label="完成时间">
                </el-table-column>
                <el-table-column prop="samplingNameOn" label="预约人员">
                </el-table-column>
                <el-table-column prop="subStatusName" label="状态">
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
                :total="total2">
              </el-pagination>
            </div>
            <div class="tableInfo" v-show="chooseIndex == 4">
              <el-table :data="tableData4" v-loading="loading3"
                :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565' }"
                :cell-style="{ 'text-align': 'center' }" style="width: 100%">
                <el-table-column prop="companyName" label="问卷类型">
                </el-table-column>
                <el-table-column prop="tasktypeName" width="80" label="是否填写">
                </el-table-column>
                <el-table-column prop="taskStartTime1" label="产品类型">
                </el-table-column>
                <el-table-column prop="updateTime" label="完成时间">
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click="lookD(scope.row)" type="text" size="small">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
                :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
                :total="total2">
              </el-pagination>
            </div>
          </el-card>
        </div>
      </div>
    </el-dialog>
    <addAppointment v-if="showAppointment" :onlyLook="onlyLook" :keyId="keyId" @closeAddAppointment="closeAddAppointment">
    </addAppointment>
    <el-dialog :visible.sync="dialogVisiblesee" width="40%" class="Visiblesee" fullscreen
      style="text-align: center; background-color: red">
      <iframe :src="previewUrl" alt="" frameborder="0" style="width: 70%; height: 860px" scrolling="no" />
    </el-dialog>
  </div>
</template>

<script>
import { postRequest } from '@/api'
import addAppointment from '@/views/appointmentManagement/components/addAppointment'
import { fileaction, fileuploadaction } from 'src/utils/cities'
export default {
  data() {
    return {
      fileUrl: fileuploadaction,
      dialogVisiblesee: false,
      upgradeDialogVisible: false,
      scope: {},
      dialogVisible: true,
      form: {
        productId: '',
      },
      time: '',
      operationType: '',
      previewUrl: '',
      value1: '',
      infomation: {},
      onlyLook: '',
      showAppointment: '',
      isShow: false,
      value2: '',
      showOther: true,
      chooseIndex: 0,
      productList: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      rightsType: [],
      tableData: [],
      total: null,
      total1: null,
      total2: null,
      loading1: true,
      loading2: false,
      loading3: false,
      pageNo: 1,
      pageSize: 10,
      pageNo1: 1,
      pageSize1: 10,
      pageNo2: 1,
      pageSize2: 10,
      activeNum: '0',
      delayRemark: '',
      buttonsList: [
        {
          name: '进度配置',
          code: '0',
        },
        {
          name: 'APP浏览记录',
          code: '1',
        },
        {
          name: '消费记录',
          code: '2',
        },
        {
          name: '预约记录',
          code: '3',
        },
        {
          name: '问卷信息',
          code: '4',
        },
      ],
      newButtonsList: [],
      typeList: [
        {
          dictionaryName: '面诊',
          dictionaryCode: '071',
        },
        {
          dictionaryName: '采样',
          dictionaryCode: '072',
        },
        {
          dictionaryName: '体检结果解读',
          dictionaryCode: '076',
        },
        {
          dictionaryName: '内部体检',
          dictionaryCode: '077',
        },
        {
          dictionaryName: '外部体检',
          dictionaryCode: '078',
        },
      ],
      result: {},
      taskName: '',
      fileName: '',
      // isShowThis: true,
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() <= Date.now()
        },
      },
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() < new Date(this.result.endTime)
        },
      },
      startDateAndEndDate: {
        disabledDate: time => {
          return this.result.startTime
            ? time.getTime() < new Date(this.result.startTime) - 86400000
            : '' || this.result.endTime
              ? time.getTime() > new Date(this.result.endTime)
              : ''
        },
      },
      outerPoints: [
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
      ],
      points: [
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
      ],
    }
  },
  props: ['recordInfo'],
  // computed:{
  //   newtime () {
  //     return new Date(this.result.endTime);
  //   }
  // },
  components: { addAppointment },
  mounted() {
    console.log(this.recordInfo, 'recordInfo')
    if (this.recordInfo) {
      this.form.desc = this.recordInfo.customerProductVos[0].delayRemark
      this.getInfo()
      if (this.recordInfo.customerProductVos[0].customerModifyNum == 1) {
        this.showOther = false
        this.isShow = true
      }
      if (this.recordInfo.productType == 'TJ') {
        this.typeList = [
          {
            dictionaryName: '面诊',
            dictionaryCode: '071',
          },
          {
            dictionaryName: '内部体检',
            dictionaryCode: '077',
          },
          {
            dictionaryName: '外部体检',
            dictionaryCode: '078',
          },
        ]
      } else {
        this.typeList = [
          {
            dictionaryName: '面诊',
            dictionaryCode: '071',
          },
          {
            dictionaryName: '采样',
            dictionaryCode: '072',
          },
        ]
      }
    }
    this.setUserInfo()
    // 查询 家庭、精英、SVIP
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '06' }).then(res => {
      console.log(res, '家庭、精英、SVIP')
      this.rightsType = res
    })
  },
  methods: {
    lookD(){

    },
    getNameByType() {
      postRequest('/product/queryProduct/', { catalogCode: 'HJ' }).then(res => {
        console.log(res)
        this.productList = res
        this.upgradeDialogVisible = true
      })
    },
    upgrade(flag) {
      this.$confirm('是否升级?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data
          if (flag) {
            data = {
              tel: this.infomation.tel,
              id: this.infomation.orderId,
              appUserName: this.infomation.customerName,
              customerNo: this.infomation.customerNo,
              productId: this.result.productId,
              upgradeProductId: this.form.productId,
            }
          } else {
            data = {
              tel: this.infomation.tel,
              id: this.infomation.orderId,
              appUserName: this.infomation.customerName,
              customerNo: this.infomation.customerNo,
              productId: this.result.productId,
            }
          }
          this.upgradeApi(data)
        })
        .catch(() => { })
    },
    upgradeApi(data) {
      postRequest('/orderComputer/addSaveOrderUpgrade', { ...data }).then(res => {
        this.$router.push({ name: 'orderManagement', query: { data: true } })
      })
    },
    delFile(key, index) {
      this.tableData1[index][key] = ''
      this.$message.success('删除文件成功!')
    },
    preview(url) {
      this.previewUrl = url
      this.dialogVisiblesee = true
    },
    fileSuccess(file, key, index) {
      this.tableData1[index][key] = file.data
      this.$message.success('上传文件成功!')
    },
    fileBeforeAvatarUpload(file) {
      const isPDF = file.type === 'application/pdf'
      if (!isPDF) {
        this.$message.error('上传文件只能是 PDF 格式!')
      } else {
        this.fileName = file.name
      }
      return isPDF
    },
    handleClose() {
      this.$emit('closerecord')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getAllProgress()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.pageNo = val
      this.getAllProgress()
    },
    handleSizeChange1(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize1 = val
      postRequest('/orderComputer/queryOrderInfoXF', {
        appUserId: this.infomation.userId,
        pageNo: this.pageNo1,
        pageSize: this.pageSize1,
      })
        .then(res => {
          console.log(res)
          this.loading2 = false
          this.tableData3 = res.data
          this.total1 = res.count
        })
        .catch(() => {
          this.loading2 = false
        })
    },
    handleCurrentChange1(val) {
      console.log(`当前页: ${val}`)
      this.pageNo1 = val
      postRequest('/orderComputer/queryOrderInfoXF', {
        appUserId: this.infomation.userId,
        pageNo: this.pageNo1,
        pageSize: this.pageSize1,
      })
        .then(res => {
          console.log(res)
          this.loading2 = false
          this.tableData3 = res.data
          this.total1 = res.count
        })
        .catch(() => {
          this.loading2 = false
        })
    },
    handleSizeChange2(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize2 = val
      postRequest('/samplingComputer/querySamplingInfoPageList', {
        userId: this.infomation.userId,
        customId: this.infomation.id,
        pageNo: this.pageNo2,
        pageSize: this.pageSize2,
      })
        .then(res => {
          console.log(res)
          this.loading3 = false
          this.total2 = res.count
          this.tableData4 = res.data
        })
        .catch(() => {
          this.loading3 = true
        })
    },
    handleCurrentChange2(val) {
      console.log(`当前页: ${val}`)
      this.pageNo2 = val
      postRequest('/samplingComputer/querySamplingInfoPageList', {
        userId: this.infomation.userId,
        customId: this.infomation.id,
        pageNo: this.pageNo2,
        pageSize: this.pageSize2,
      })
        .then(res => {
          console.log(res)
          this.loading3 = false
          this.total2 = res.count
          this.tableData4 = res.data
        })
        .catch(() => {
          this.loading3 = true
        })
    },
    outPoint(i) {
      return this.outerPoints[i]
    },
    point(i) {
      return this.points[i]
    },
    // handleSelect(val){
    //   console.log(val)
    //   this.chooseIndex = val
    // },
    showDetail(val) {
      console.log(val)
      this.keyId = val.id
      this.showAppointment = true
      this.onlyLook = true
    },
    closeAddAppointment() {
      this.showAppointment = false
      this.getAllProgress()
    },
    handleClick(index) {
      console.log(index)
      this.chooseIndex = index
      if (this.chooseIndex == 1) {
        postRequest('/computerLog/queryLogInfo', { appUserId: this.infomation.userId, pageNo: 1, pageSize: 20 }).then(
          res => {
            console.log(res)
            this.tableData2 = res.data
          },
        )
      }
      if (this.chooseIndex == 2) {
        this.loading2 = true
        postRequest('/orderComputer/queryOrderInfoXF', {
          appUserId: this.infomation.userId,
          pageNo: this.pageNo1,
          pageSize: this.pageSize1,
        })
          .then(res => {
            console.log(res)
            this.loading2 = false
            this.tableData3 = res.data
            this.total1 = res.count
          })
          .catch(() => {
            this.loading2 = false
          })
      }
      if (this.chooseIndex == 3) {
        this.loading3 = true
        postRequest('/samplingComputer/querySamplingInfoPageList', {
          userId: this.infomation.userId,
          customId: this.infomation.id,
          pageNo: this.pageNo2,
          pageSize: this.pageSize2,
        })
          .then(res => {
            console.log(res)
            this.loading3 = false
            this.total2 = res.count
            this.tableData4 = res.data
          })
          .catch(() => {
            this.loading3 = true
          })
      }
    },
    changeDate() {
      this.$forceUpdate()
    },
    handleOpen(val) {
      if (!this.result.houseDocterName && val.tasktypeCode == '071') {
        this.$message.error('请先选择服务医生!')
        return
      }
      this.operationType = 1
      this.keyId = val.id
      this.showAppointment = true
      this.onlyLook = false
    },
    downloadFile() {
      window.location.href =
        fileaction +
        `/orderComputer/exportOrderInfoExcel?computerToken=${JSON.parse(localStorage.getItem('newToken')).computerToken
        }&userId=${this.infomation.userId}`
    },
    changeTaskName(val, index) {
      if (this.infomation.productType == 'TJ') {
        let list = this.tableData1.filter(item => {
          return item.tasktypeCode == val
        })
        if (list.length > 1) {
          this.tableData1[index].tasktypeCode = ''
          this.$message.error('类型每个只能选择一项!')
          return
        }
      }
      this.typeList.map(item => {
        if (item.dictionaryCode == val) {
          this.tableData1[index].taskName = item.dictionaryName
        }
      })
    },
    open() {
      this.value1 = this.result.endTime
      if (this.result.customerModifyNum == 0) {
        this.isShow = true
      } else {
        this.$message({
          message: '延期权限已使用, 无法再次延期',
          type: 'warning',
        })
      }
    },
    delay() {
      console.log(this.value1)
      if (this.value1) {
        this.isShow = false
        this.showOther = false
        let data = {
          startTime: this.result.startTime,
          endTime: this.value2,
          delayRemark: this.form.desc,
          id: this.recordInfo.customerProductVos[0].id,
        }
        postRequest('/customer/customerProductDelay', data)
          .then(res => {
            console.log(res)
            this.getInfo()

            this.$message({
              message: '延期成功',
              type: 'success',
            })
          })
          .catch(() => {
            this.showOther = true
          })
      } else {
        this.$message({
          message: '请选择延期时间',
          type: 'warning',
        })
      }
    },
    cancelDelay() {
      this.isShow = false
    },
    getInfo() {
      let data = {
        id: this.recordInfo.customerProductVos[0].id,
      }
      postRequest('/customer/queryCustomerById', data).then(res => {
        console.log(res, '根据id查询信息')
        this.result = res.customerProductVos[0]
        this.infomation = res
        if (this.infomation.productType == 'DP') {
          this.activeNum = '1'
          this.newButtonsList = this.buttonsList
          this.newButtonsList = this.newButtonsList.slice(1)
          console.log(this.newButtonsList)
          this.chooseIndex = 1
          this.handleClick(this.chooseIndex)
        } else {
          this.newButtonsList = this.buttonsList
        }
        this.getAllProgress()
      })
    },
    changeTime(val) {
      this.$forceUpdate()
    },
    handleSure(val) {
      console.log(val)
      if (val.id) {
        let data = {
          id: val.id,
          remark: val.remark,
          taskTime: val.samplingTime,
          taskCode: val.tasktypeCode,
          userName: this.recordInfo.customerName,
          userId: this.infomation.userId,
          customId: this.infomation.id,
          operationType: this.operationType ? this.operationType : '',
          orderId: this.infomation.orderId,
          deptId: this.infomation.customerProductVos[0].companyId,
          productId: this.infomation.productId,
          productName: this.infomation.productName,
          taskName: val.taskName,
          medicalExaminationProgram: val.medicalExaminationProgram,
          precautions: val.precautions,
        }
        postRequest('/progress/update', data).then(res => {
          console.log(res)
          this.getAllProgress()
          this.$message({
            message: '修改成功',
            type: 'success',
          })
        })
      } else {
        console.log(123)
        let data = {
          remark: val.remark,
          taskTime: val.samplingTime,
          taskCode: val.tasktypeCode,
          userName: this.infomation.customerName,
          userId: this.infomation.userId,
          customId: this.infomation.id,
          operationType: this.operationType ? this.operationType : '',
          orderId: this.infomation.orderId,
          deptId: this.infomation.customerProductVos[0].companyId,
          productId: this.infomation.productId,
          productName: this.infomation.productName,
          taskName: val.taskName,
          medicalExaminationProgram: val.medicalExaminationProgram,
          precautions: val.precautions,
        }
        postRequest('/progress/addSave', data).then(res => {
          this.getAllProgress()
          this.$message({
            message: '添加成功',
            type: 'success',
          })
        })
      }
    },
    handleAdd() {
      let a = {
        taskType: '',
        taskTime: '',
        remark: '',
        medicalExaminationProgram: '',
        precautions: '',
        substatus: '',
      }
      this.tableData1.unshift(a)
    },
    changeInput() {
      this.$forceUpdate()
    },
    getAllProgress() {
      let data = {
        userId: this.infomation.userId,
        customId: this.infomation.id,
        productId: this.infomation.productId,
        orderId: this.infomation.orderId,
        customerTel: this.infomation.tel,
        companyId: this.infomation.customerProductVos[0].companyId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        newOrderFlag: true,
      }
      postRequest('samplingComputer/querySamplingInfoPageList', data)
        .then(res => {
          console.log(res, '查询所有进度')
          this.loading1 = false
          this.total = res.count
          this.tableData1 = res.data.filter(item => {
            return item.tasktypeCode != '076'
          })
        })
        .catch(() => {
          this.loading1 = false
        })
    },
    setUserInfo() { },
    handleDeleteprogress(index, val, type) {
      console.log(index)
      // console.log(val)
      if (val.id) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            console.log(val, '进度删除项信息')
            let data = {
              id: val.id,
            }
            postRequest(type ? '/progress/delete' : '/samplingComputer/deleteSamplingById', data).then(res => {
              console.log(res, '删除')
              this.$message({
                message: '删除成功',
                type: 'success',
              })
              this.getAllProgress()
            })
          })
          .catch(() => {
            this.$message({
              type: 'warning',
              message: '已取消删除',
            })
          })
      } else {
        console.log('删除')
        this.tableData1.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: space-between;

  .left {
    width: 330px;
    margin-right: 20px;

    .header {
      // display: flex;
      // align-items: center;
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 29px;

      :first-child {
        margin-bottom: 20px;
      }

      :nth-child(2) {
        margin-bottom: 20px;
        font-size: 16px;
      }

      :last-child {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
  }

  .right {
    flex: 1;
  }
}

.pic {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 0px !important;
}

.buttonLine {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;

  .el-menu {
    display: flex;
    border: none;
  }
}

.tableInfo {

  // margin-top: 40px;
  .el-button {
    margin-bottom: 10px;
  }
}

.button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.task {
  float: right;
}

.button-1 {
  float: right;
}

.el-menu-item.is-active {
  background-color: #5967ff;
  color: #fff;
}

.bigOutBox {
  max-height: 400px;
  overflow-x: hidden;
}

.outBox {
  padding-left: 30%;
}

.word {
  display: flex;
  align-items: center;
  margin: 0 auto;

  .outPoint {
    border: 1px solid;
    border-radius: 50%;

    .point {
      margin: 2px;
      width: 4px;
      height: 4px;
      background: #999;
      border-radius: 50%;
    }
  }

  .time {
    color: #999;
  }
}

.line {
  width: 1px;
  height: 15px;
  background: #999;
  margin-left: 4px;
}

.el-menu-item {
  height: 40px;
  line-height: 40px;
}

.is-active {
  background: #5967ff !important;
}

.el-form-item {
  margin-bottom: 0px !important;
}

.lastTen {
  text-align: center;
  color: #999;
  margin-top: 20px;
}

.operateBox {
  display: flex;
  justify-content: center;
}</style>
