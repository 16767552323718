<template>
  <el-dialog title="退费" :close-on-click-modal="false" :visible.sync="dialogVisible" width="50%" @close="handlerClose">
    <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" class="demo-dynamic">
      <el-form-item>
        <el-input v-model="domain.value"></el-input><el-button icon="el-icon-search"></el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="12">会员名称</el-col>
      <el-col :span="12">张三</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">剩余服务时间</el-col>
      <el-col :span="12">123 天</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">剩余金额</el-col>
      <el-col :span="12">8200 元</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">退费备注</el-col>
      <el-col :span="12"><el-input v-model="mark"></el-input></el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handlerClose">取 消</el-button>
      <el-button type="primary" @click="handlerClose">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      dynamicValidateForm: {
        domains: [
          {
            value: '',
          },
        ],
      },
      domain: {
        value: '',
      },
      mark: '',
    }
  },
  methods: {
    handlerClose() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item__content {
  display: flex;
}
.el-col {
  font-size: 20px;
  padding: 5px 10px;
  color: #000;
}
</style>
